<template>
    <div class="wrapper">
        <div class="login-container">
            <div class="header">
                <img src="../assets/liveIcon.png" alt="" class="logo">
                <b>网剧推广</b>
            </div>
            <div class="title">
                <b>登 录</b>
            </div>
            <el-form :model="user" :rules="rules" ref="userForm">
                <el-form-item prop="username">
                    <el-input size="medium" prefix-icon="el-icon-user" v-model="user.username" placeholder="请输入账号"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input size="medium" prefix-icon="el-icon-lock" show-password v-model="user.password" placeholder="请输入密码"></el-input>
                </el-form-item>
                <el-form-item>
                    <div class="code-container">
                        <el-input size="mid" prefix-icon="el-icon-s-opportunity" v-model="code" class="code-input" placeholder="请输入验证码"></el-input>
                        <span @click="refreshCode" class="identify">
                          <Identify :identifyCode="identifyCode" :contentWidth="135"></Identify>
                        </span>
                    </div>
                </el-form-item>
                <div >
                    <div >
<!--                        <el-button type="warning" size="small" @click="$router.push('/register')">注册</el-button>-->
                        <el-button type="primary" size="small" @click="login" style="width: 100%">登录</el-button>
                    </div>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
    import { setRoutes } from "@/router";
    import Identify from "@/views/Identify";
    export default {
        name: "Login",
        data() {
            return {
                user: {
                    username: '',
                    password: ''
                },
                rules: {
                    username: [
                        { required: true, message: '请输入用户名', trigger: 'blur' },
                        { min: 3, max: 10, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                },
                code: '',
                identifyCode: '',
                // 验证码规则
                identifyCodes: '3456789ABCDEFGHGKMNPQRSTUVWXY'
            }
        },
        components: { Identify },
        mounted() {
            this.refreshCode();
        },
        methods: {
            // 切换验证码
            refreshCode() {
                this.identifyCode = '';
                this.makeCode(this.identifyCodes, 4);
            },
            // 生成随机验证码
            makeCode(o, l) {
                for (let i = 0; i < l; i++) {
                    this.identifyCode += this.identifyCodes[Math.floor(Math.random() * this.identifyCodes.length)];
                }
            },
            login() {
                if (this.code.toLowerCase() !== this.identifyCode.toLowerCase()) {
                  this.$message({
                    type: "error",
                    message: "验证码错误"
                  })
                  return;
                }
                this.$refs['userForm'].validate((valid) => {
                    if (valid) {
                        this.request.post("user/login", this.user).then(res => {
                            if (res.code === '200') {
                                localStorage.setItem("user", JSON.stringify(res.data));  // 存储用户信息到浏览器
                                localStorage.setItem("menus", JSON.stringify(res.data.menus));  // 存储用户信息到浏览器
                                // 动态设置当前用户的路由
                                setRoutes()
                                this.$router.push(this.ADMIN_PAGE)
                                // if (this.ADMIN_PAGE && res.data.role=='ROLE_ADMIN'){
                                //     this.$router.push(this.ADMIN_PAGE)
                                // }else {
                                //     this.$router.push("/")
                                // }

                                this.$message.success("登录成功");
                            }
                        });
                    }
                });
            }
        }
    }
</script>

<style>
    .wrapper {
        background-image: url(../assets/bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -10;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .login-container {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        width: 80%;
        max-width: 400px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    .header {
        text-align: center;
        font-size: 24px;
        margin: 20px 0;
    }

    .logo {
        width: 26px;
        margin: 0 5px;
        vertical-align: middle;
    }

    .title {
        text-align: center;
        font-size: 20px;
        color: #06436f;
        margin: 10px 0;
    }

    .code-container {
        display: flex;
    }

    .code-input {
        width: 60%;
    }

    .identify {
        cursor: pointer;
        width: 40%;
        flex: 1;
    }

    .back-home {
        line-height: 56px;
        cursor: pointer;
        font-size: 14px;
        color: #606266;
    }

    .login-button {
        margin: 10px 0;
        text-align: right;
    }

    @media (max-width: 600px) {
        .login-container {
            width: 90%;
            padding: 15px;
        }

        .header {
            font-size: 20px;
        }

        .title {
            font-size: 18px;
        }

        .back-home {
            font-size: 12px;
            line-height: 46px;
        }

        .login-button {
            text-align: center;
        }
        #s-canvas{
            width: 112px;
            height: 40px;
        }
    }
</style>
